export const tableModel = [
  { title: '下单人信息', key: 'user', slots: { customRender: 'user' }, width: '240px' },
  { title: '陪玩信息', key: 'npc', slots: { customRender: 'npc' }, width: '160px' },
  { title: '技能信息', key: 'skill', slots: { customRender: 'skill' }, width: '160px' },
  { title: '订单状态', key: 'status', slots: { customRender: 'status' }, width: '160px' },
  { title: '支付信息', key: 'pay', slots: { customRender: 'pay' }, width: '220px' },
  { title: '提成信息', key: 'rebate', slots: { customRender: 'rebate' }, width: '160px' },
  { title: '订单信息', key: 'order', slots: { customRender: 'order' }, width: '220px' },
  { title: '操作', key: 'action', slots: { customRender: 'action' }, width: '200px', fixed: 'right' },
];

export const logTableModel = [
  { title: '时间', key: 'InsertDate', dataIndex:'InsertDate',width: '200px' },
  { title: '操作人', key: 'Operator', dataIndex:'Operator',width: '200px' },
  { title: '备注', key: 'LogContent', dataIndex:'LogContent' },
];

export const searchModel = {
  NickName: '',
  Mobile: '',
  LinkPhone: '',
  Contacts: '',
  OrderType: '',
  SecondCategory: '',
  FirstCategory: '',
  OrderStatus: '',
  OrderId: '',
  PageIndex: 1,
  PageSize: 10,
};

export const orderStatus = [
  { code: 0, value: '待支付' },
  { code: 1, value: '待服务' },
  { code: 2, value: '待确认' },
  { code: 3, value: '服务中' },
  { code: 4, value: '已完成' },
  { code: 5, value: '已取消' },
  { code: 6, value: '退款中' },
  { code: 7, value: '退款成功' },
];

export const orderTypes = [
  { code: 0, value: '正常订单' },
  { code: 1, value: '续费订单' },
];


export const refundTableModel = [
  { title: '用户ID', key: 'UserId',dataIndex:'UserId' },
  { title: '订单标题', key: 'OrderTitle',dataIndex:'OrderTitle' },
  { title: '订单ID', key: 'OrderId',dataIndex:'OrderId' },
  { title: '支付方式', key: 'TradeAgencyStr',dataIndex:'TradeAgencyStr' },
  { title: '退款订单ID', key: 'RefundId',dataIndex:'RefundId' },
  { title: '退款订单原因', key: 'RefundReason',dataIndex:'RefundReason' },
  { title: '支付金额', key: 'PayPrice',dataIndex:'PayPrice' },
  { title: '退款金额', key: 'RefundFee',dataIndex:'RefundFee' },
  { title: '退款金额', key: 'RefundFee',dataIndex:'RefundFee' },
  { title: '退款状态', key: 'RefundStatusStr',dataIndex:'RefundStatusStr' },
  { title: '操作', key: 'action', slots: { customRender: 'action' }, width: '200px', fixed: 'right' },
];

// export const logTableModel = [
//   { title: '时间', key: 'InsertDate', dataIndex:'InsertDate',width: '200px' },
//   { title: '操作人', key: 'Operator', dataIndex:'Operator',width: '200px' },
//   { title: '备注', key: 'LogContent', dataIndex:'LogContent' },
// ];

export const refundSearchModel = {
  RefundStatus: '',
  OrderId: '',
  PageIndex: 1,
  PageSize: 10,
};

export const refundStatus = [
  { code: 0, value: '申请中' },
  { code: 1, value: '退款拒绝' },
  { code: 2, value: '退款通过' },
  { code: 3, value: '退款失败' },
  { code: 99, value: '退款成功' },
];

// export const orderTypes = [
//   { code: 0, value: '正常订单' },
//   { code: 1, value: '续费订单' },
// ];
