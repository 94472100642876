
import { defineComponent, computed, ref } from 'vue';
import { useInitRoute } from '@/util/router';
import { logTableModel } from '@/data/modules/order/list';

import { orderLog } from '@/api/order';
import { awaitWrap } from '@/util/index';
export default defineComponent({
  name: 'Home',
  setup() {
    const { route } = useInitRoute();
    const info = computed(() => {
      return JSON.parse(sessionStorage.getItem(`orderDetail${route.query.id}`) || '{}');
    });

    const tableSource = ref([]);
    //获取订单日志
    async function getOrderLog() {
      const { code, data } = await awaitWrap(orderLog(route.query.id));
      if (code === 200) {
        tableSource.value = data.data;
      }
    }
    getOrderLog();

    return { info, tableSource, logTableModel };
  },
});
