import { reactive,  ref } from 'vue';
import _ from 'lodash';
import { refundTableModel, refundSearchModel, refundStatus } from '@/data/modules/order/list';
import { refundList,refundReject,refundPassed } from '@/api/order';
import { awaitWrap } from '@/util/index';
import { useInitRouter } from '@/util/router';
import { ModalError } from '@/util/message';
import { message } from 'ant-design-vue';

//表单hooks
export function useTable(): any {
  const { routerPush } = useInitRouter();

  //搜索数据
  const searchRef = reactive(_.clone(refundSearchModel));

  //重置表单
  function handleReset() {
    _.assignIn(searchRef, refundSearchModel);
  }

  //分页数据
  const pagination = reactive({
    current: 1,
    total: 10,
  });

  //表格数据
  const tableColumns = refundTableModel;
  const tableSource = ref([]);
  const tableLoading = ref(false);

  // 获取列表
  async function getList() {
    tableLoading.value = true;
    const { code, data } = await awaitWrap(refundList(searchRef));
    tableLoading.value = false;
    if (code === 200) {
      tableSource.value = data.data.DataList;
      pagination.total = data.data.RecordCount;
    }
  }

  //分页操作
  function handlePageChange(val: any) {
    pagination.current = val;
    searchRef.PageIndex = val;
    getList();
  }

  getList();


  //同意退款
  function handleRefundPassed(el:any){
    ModalError('你确定退款', async () => {
      const { code } = await awaitWrap(refundPassed(el.RefundId));
      return new Promise((resolve, reject) => {
        if (code === 200) {
          message.success('成功提起微信退款');
          getList();
          resolve('');
        } else {
          reject();
        }
      });
    });
  }


  const rejectReason = ref('');
  const rejectId = ref(0)
  const rejectReasonVisible = ref(false);

  function handleRefundShowBox(el:any){
    rejectId.value = el.RefundId;
    rejectReasonVisible.value = true;
  }

  //同意拒绝
  async function handleRefundReject(){
    if(!rejectReason.value){
      return message.warning('输入拒绝理由');
    }
    const { code } = await awaitWrap(refundReject(rejectId.value,rejectReason.value));

    if (code === 200) {
    rejectReasonVisible.value = false;

      message.success('拒绝成功');
      getList();
    }
  }

  return {
    tableLoading,
    refundStatus,
    tableColumns,
    tableSource,
    pagination,
    searchRef,
    handleRefundShowBox,
    handleRefundReject,
    rejectId,
    rejectReasonVisible,
    rejectReason,
    handleRefundPassed,
    handlePageChange,
    getList,
    handleReset,
  };
}
