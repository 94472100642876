
import { defineComponent } from 'vue';
import { useTable } from './list';
import { useCategoryTable } from '@/views/npc/category/setup';

import YImage from '@/components/modules/image/index.vue';
const { tableSource: categorylist } = useCategoryTable();

export default defineComponent({
  name: 'orderList',
  components: { YImage },
  setup() {
    return {
      categorylist,
      ...useTable(),
    };
  },
});
