import request from './axios';

export const orderList = (params: any): Promise<any> => {
  return request({
    url: '/order/list',
    method: 'get',
    params: params,
  });
};

//订单日志
export const orderLog = (id: any): Promise<any> => {
  return request({
    url: `/order/${id}/log`,
    method: 'get',
  });
};

//陪玩出发
export const orderNpcService = (id: any): Promise<any> => {
  return request({
    url: `/order/${id}/service`,
    method: 'put',
  });
};

//优惠券订单列表
export const couponOrderlist = (params: any): Promise<any> => {
  return request({
    url: `/coupon/order/list`,
    method: 'get',
    params: params,
  });
};


//退款订单列表
export const refundList= (params: any): Promise<any> => {
  return request({
    url: `/order/refund/list`,
    method: 'get',
    params: params,
  });
};

//退款拒绝
export const refundReject= (id:any,RejectReason:any): Promise<any> => {
  return request({
    url: `/order/refund/${id}/reject`,
    method: 'put',
    data: {RejectReason},
  });
};

//退款拒绝
export const refundPassed= (id:any): Promise<any> => {
  return request({
    url: `/order/refund/${id}/passed`,
    method: 'put',
  });
};
