export const tableModel = [
  { title: '店铺信息', key: 'ShopName',dataIndex:'ShopName' },
  { title: '优惠券名称', key: 'CouponName',dataIndex:'CouponName' },
  { title: '优惠券类型',key: 'CouponTypeStr',dataIndex:'CouponTypeStr' },
  { title: '数量', key: 'BuyCount',dataIndex:'BuyCount' },
  { title: '销售价', key: 'UnitPrice',dataIndex:'UnitPrice' },
  { title: '支付金额', key: 'TotalPrice',dataIndex:'TotalPrice' },
  { title: '下单时间', key: 'InsertDate',dataIndex:'InsertDate' },
  { title: '状态', key: 'CouponOrderStatusStr',dataIndex:'CouponOrderStatusStr' },
];

export const searchModel = {
  CouponOrderStatus: '',
  CouponRuleId: '',
  ShopId: '',
  PageIndex: 1,
  PageSize: 10,
};

export const orderStatus = [
  { code: '', value: '全部' },
  { code: 0, value: '待支付' },
  { code: 1, value: '已支付' },
  { code: 2, value: '已取消' },
];
