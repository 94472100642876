
import { defineComponent } from 'vue';
import { useTable } from './list';
import { Modal } from 'ant-design-vue';
export default defineComponent({
  components: { AModal: Modal },
  setup() {
    return { ...useTable() };
  },
});
