import { reactive, computed, ref, getCurrentInstance } from 'vue';
import _ from 'lodash';
import { tableModel, searchModel, orderStatus, orderTypes } from '@/data/modules/order/list';
import { orderList,orderNpcService } from '@/api/order';
import { awaitWrap } from '@/util/index';
import { useInitRouter } from '@/util/router';
import { ModalError } from '@/util/message';
import { message } from 'ant-design-vue';

//表单hooks
export function useTable(): any {
  const { routerPush } = useInitRouter();

  //搜索数据
  const searchRef = reactive(_.clone(searchModel));

  //重置表单
  function handleReset() {
    _.assignIn(searchRef, searchModel);
  }

  //分页数据
  const pagination = reactive({
    current: 1,
    total: 10,
  });

  //表格数据
  const tableColumns = tableModel;
  const tableSource = ref([]);
  const tableLoading = ref(false);

  // 获取列表
  async function getList() {
    tableLoading.value = true;
    const { code, data } = await awaitWrap(orderList(searchRef));
    tableLoading.value = false;
    if (code === 200) {
      tableSource.value = data.data.DataList;
      pagination.total = data.data.RecordCount;
    }
  }

  //分页操作
  function handlePageChange(val: any) {
    pagination.current = val;
    searchRef.PageIndex = val;
    getList();
  }

  getList();

  function handleDetail(el: any) {
    sessionStorage.setItem(`orderDetail${el.OrderId}`, JSON.stringify(el));
    routerPush({
      path: '/order/detail',
      query: {
        id: el.OrderId,
      },
    });
  }

  function handleOutset(el:any){
    // const { code, data } = await awaitWrap(orderList(searchRef));
    // tableLoading.value = false;
    // if (code === 200) {
    //   tableSource.value = data.data.DataList;
    //   pagination.total = data.data.RecordCount;
    // }
    ModalError('你确定陪玩已经出发', async () => {
      const { code } = await awaitWrap(orderNpcService(el.OrderId));
      return new Promise((resolve, reject) => {
        if (code === 200) {
          message.success('修改成功！请通知玩家陪玩已出发');
          getList();
          resolve('');
        } else {
          reject();
        }
      });
    });
  }

  return {
    tableLoading,
    orderStatus,
    tableColumns,
    tableSource,
    pagination,
    searchRef,
    handleOutset,
    orderTypes,
    // getNpcList,
    // handleSkillList,
    handleDetail,
    handlePageChange,
    getList,
    handleReset,
  };
}
